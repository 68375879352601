<template>
  <toolbar />
  <a class="branding" href="https://zoovu.com/?utm_source=&fairshopping&utm_medium=fairshopping&utm_campaign=fairshopping/" target="_blank" rel="noopener noreferrer">
    <img src="./assets/powered-by-zoovu.png" alt="Powered by Zoovu" class="branding__logo">
  </a>
  <main class="main" :class="'main--' + $route.name" role="main">
    <router-view />
  </main>
</template>

<script>
import './css/style.scss';
import Toolbar from './components/Toolbar.vue';

export default {
	name: 'App',
	components: { Toolbar },
	mounted() {
		const script = document.createElement('script');
		script.src = 'https://js.sitesearch360.com/plugin/bundle/3518.js';
		script.async = true;
		document.head.appendChild(script);
	}
};
</script>

<style >

</style>
<template>
  <div id="immersive">

  </div>
</template>

<script>
export default {
	name: 'ShoeFinder',

	created() {
		window.ss360Config = {};
		window.zoovuSearchConfig = {};
	},

	mounted() {
		const script = document.createElement('script');
		script.src = 'https://immersive.search.zoovu.com/bundle/20870.js';
		script.async = true;
		document.head.appendChild(script);
	}
};
</script>
import { createWebHistory, createRouter } from 'vue-router';
import ProductDetails from '@/components/ProductDetails.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import Home from '@/components/Home.vue';
import ShoeFinder from '@/components/ShoeFinder.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/product',
		name: 'ProductDetails',
		component: ProductDetails
	},
	{
		path: '/shoes',
		name: 'ShoeFinder',
		component: ShoeFinder
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicy
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

export default router;